body {
    margin: 0;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    background: linear-gradient(to bottom right, #eef2f3, #dfe9f3);
    min-height: 100vh;
  }
  
  .orgchart-container {
    padding: 2rem;
    overflow-x: auto;
  }
  
  .orgchart-toolbar {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .orgchart-toolbar button {
    padding: 0.5rem 1rem;
    font-weight: 500;
    background-color: #4285f4;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  
  .orgchart-toolbar button:hover {
    background-color: #3367d6;
    transform: scale(1.05);
  }
  
  .org-node {
    background: #ffffff;
    border-radius: 10px;
    padding: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
    text-align: center;
    width: 160px;
    transition: transform 0.2s ease-in-out;
  }
  
  .org-node:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.15);
  }
  
  .org-node img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #ddd;
    margin-bottom: 6px;
  }
  
  .org-node h4 {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    color: #222;
  }
  
  .org-node p {
    margin: 0;
    font-size: 12px;
    color: #555;
  }
  
  .orgchart.my-orgchart {
    background-color: white !important;
    background-image: none !important;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
    border: 1px solid #ddd;
  }