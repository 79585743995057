.admin-dashboard {
  background: #FFFFFF;
  padding: 30px;
}

/* Card Base */
.dashboard-card {
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.06);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.dashboard-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 12px 28px rgba(0, 0, 0, 0.08);
}

/* Header */
.dashboard-card .card-header {
  background: #f0f2f5;
  padding: 16px 24px;
  font-weight: 600;
  font-size: 18px;
  color: #333;
  border-bottom: 1px solid #e0e0e0;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

/* Scrollable Card Body */
.dashboard-card .card-body {
  padding: 20px 20px;
  overflow-y: auto;
  max-height: 300px;
  flex-grow: 1;
}

/* Smooth Scrollbar */
.dashboard-card .card-body::-webkit-scrollbar {
  width: 6px;
}
.dashboard-card .card-body::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}

/* List Layout */
.dashboard-card ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dashboard-card li {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
  border-bottom: 1px solid #f1f1f1;
}

.dashboard-card li:last-child {
  border-bottom: none;
}

/* Initial Circle */
.initial {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: linear-gradient(145deg, #007bff, #0056b3);
  color: white;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-size: 16px;
  box-shadow: 0 3px 6px rgba(0, 123, 255, 0.3);
}

/* Info Block */
.name {
  font-weight: bold;
  font-size: 15px;
  margin: 0;
  color: #222;
}

.job-title,
.time-left,
.date {
  font-size: 13px;
  color: #666;
  margin: 0;
}

/* Edit Icon */
.edit-icon {
  margin-left: auto;
  color: #888;
  cursor: pointer;
  transition: color 0.2s;
}
.edit-icon:hover {
  color: #007bff;
}

/* No Data State */
.dashboard-card .card-body p {
  text-align: center;
  font-style: italic;
  color: #999;
  padding: 10px 0;
  margin: 0;
}
/* Card color styles */
.card-yellow {
  background-color: #ffe6a7;
  border-left: 5px solid #ffb300;
}
.card-light-green {
  background-color: #e6ffec;
  border-left: 5px solid #27ae60;
}
.card-green {
  background-color: #d1f7c4;
  border-left: 5px solid #2ecc71;
}
.card-blue {
  background-color: #d4e8fd;
  border-left: 5px solid #3498db;
}
.card-red {
  background-color: #ffd6d6;
  border-left: 5px solid #e74c3c;
}
.card-purple {
  background-color: #f3d9ff;
  border-left: 5px solid #9b59b6;
}